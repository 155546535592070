#interests-section {
    background: rgb(47,144,176);
	background: linear-gradient(77deg, rgba(47,144,176,1) 0%, rgba(49,25,109,1) 100%);
}

.interests-col {
    text-align: center;
    padding-top: 75px;
    padding-bottom: 75px;
}

#interests-heading {
    font-family: 'Lobster', cursive;
    color: white;
    font-size: 3em;
    margin-bottom: 1em;
}

#interests-list {
	margin: auto;
	width: 80%;
	text-align: center;
}

#interests-list ul {
	display: inline-block;
    text-align:left;
	font-family: 'American Typewriter', serif;
	font-size: 2.0em;
	color: white;
	list-style-type: disc;
  	list-style: inside;
}

#interests-list li {
	margin-bottom: .5em;
	display: list-item;
}