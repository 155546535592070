#about-section {
    background-color: #E0E0E0;
}

.about-col {
    text-align: center;
    padding-top: 75px;
    padding-bottom: 75px;
}

#about-heading {
    font-family: 'Lobster', cursive;
    color: black;
    font-size: 3em;
}

.about-paragraph {
    text-align: left;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'American Typewriter', serif;
    font-size: 1.5em;
    font-weight: 500;
}