.stat-banner-col {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

#stat-banner-row {
    background: rgb(108,116,109);
	background: linear-gradient(77deg, rgba(108,116,109,1) 0%, rgba(168,184,182,1) 100%);
}

.stat-banner-quantity {
    font-family: 'American Typewriter', serif;
    font-size: 38px;
    color: white;
}

.stat-banner-heading {
    font-family: 'American Typewriter', serif;
    font-size: 24px;
    color: white;
}

.projects-col {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 80px;
}

@media screen and (max-width: 576px) {

    .projects-col {
        padding-bottom: 40px;
    }

}

#projects-heading-col {
    text-align: center;
    padding-top: 80px;
    padding-bottom: 20px;
}

#projects-heading {
    font-family: 'Lobster', cursive;
    color: black;
    font-size: 3em;
}

.projects-pic {
    border-radius: 8px;
}

.projects-subheading {
    font-family: 'American Typewriter', serif;
    font-size: 1.8em;
    font-weight: bold;
}

.projects-paragraph {
    text-align: left;
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    font-family: 'American Typewriter', serif;
    font-size: 1.5em;
    font-weight: 500;
}