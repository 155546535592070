#profilepic-container {
    background-color: black;
}

#profilepic {
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 3em;
    object-fit: cover;
    vertical-align: bottom;
}

@media screen and (max-width: 50em) {
    #profilepic {
        height: 45.625em;
    }
}