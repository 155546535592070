.contact-col {
    text-align: center;
    padding-top: 75px;
    padding-bottom: 75px;
}

#contact-heading {
    font-family: 'Lobster', cursive;
    color: black;
    font-size: 3em;
    margin-bottom: 1em;
}

.contact-text {
    font-family: 'American Typewriter', serif;
	font-size: 1.8em;
	color: black;
    margin-bottom: 25px;
}

#footer {
    text-align: center;
    margin-bottom: 25px;
}

#footer-text {
    font-family: 'American Typewriter', serif;
	font-size: 0.75em;
    color: grey;
}