#scroll-to-top {
  z-index: 100;
  position: fixed;
  bottom: 5%;
  right: 5%;
}


.navbar {
  background-color: black !important;
  padding: .8rem 1rem !important;
}

.nav-links {
  color: white !important;
  margin-left: 25px;
  font-family: 'Lobster', cursive;
  font-size: 30px;
  padding: 0.6em 1em !important;
  cursor: pointer;
}

.nav-links:hover {
  color: white;
  text-decoration: none;
}

.socials-container {
  display: block;
  margin-right: 25px;
}

@media only screen and (max-width: 991px) {

  .socials-container {
    margin-right: 15px;
  }

}

.socials-container.expanded {
  margin-left: 58px;
  padding-top: 10px;
  padding-bottom: 20px;
}

#linkedin-container {
  display: inline-block;
  margin-right: 50px;
}

#email-container {
  display: inline-block;
}